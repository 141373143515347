import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby'; // eslint-disable-line

const SEO = ({ description, lang, title, keywords, url, imagem }) => {
  return (
    <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
            url
            imagem
            author
          }
        }
      }
    `}
    render={data => (
      <Helmet
      title={`${title} | ${data.site.siteMetadata.title}`}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: description || data.site.siteMetadata.description,
        },
        {
          name: "keywords",
          content: keywords || data.site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title || data.site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description || data.site.siteMetadata.description,
        },
        {
          property: `og:url`,
          content: url || data.site.siteMetadata.url,
        },
        {
          property: `og:image`,
          content: imagem || data.site.siteMetadata.imagem,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title || data.site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: description || data.site.siteMetadata.description,
        },
      ]}
      defer={false}
    />
    )}
    />
  )
}

SEO.defaultProps = {
  lang: `pt-BR`,
  description: ``,
  keywords: ``,
  url: ``,
  imagem: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  url: PropTypes.string,
  imagem: PropTypes.string,
}

export default SEO
